import YAML2JSONComponent from "@jordibosch20/software_tools_package/dist/pages/YAML2JSON.js"
import styles from "./App.module.css";
import { getYAMLToJSON } from "@jordibosch20/software_tools_package/dist/utils/yaml2json.js";
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
      <div className={styles.App}>
        <YAML2JSONComponent title={"YAML to JSON"} f={getYAMLToJSON}></YAML2JSONComponent>
      </div>
      <div className={styles.text}>
        <h1>Convert YAML to JSON Online</h1>
        <h2>Translate YAML to JSON instantaneously</h2>
        <p>The conversion of YAML (YAML Yet Another Markup Language) files to JSON (JavaScript Object Notation) format. This conversion is crucial for developers and IT professionals who work with different data serialization formats to ensure compatibility and functionality across various applications and systems. Enter our online YAML to JSON converter, a tool designed to simplify your workflow, reduce manual coding errors, and save precious time.</p>
        <h3>Why Convert YAML to JSON?</h3>
        <p>YAML and JSON are popular data serialization formats, each with its own set of advantages. YAML's human-readable format makes it a preferred choice for configuration files, while JSON&rsquo;s lightweight and easy-to-parse structure makes it ideal for data interchange between servers and web applications. The conversion between these two formats is vital in scenarios where a system or application requires a specific format to function correctly.</p>
        <h3>Key Features of Our YAML to JSON Converter</h3>
        <ul>
        <li><strong>Instant Conversion</strong>: Upload your YAML file, and our tool instantly translates it into JSON format.</li>
        <li><strong>User-Friendly Interface</strong>: Designed with simplicity in mind, our converter is easy to use, requiring no technical expertise.</li>
        <li><strong>Safe and Secure</strong>: We prioritize your data's security. Uploaded files are only "uploaded" in your device and never leave there. Our code runs on client side and we never store anything in the server, this way you can be copy privated files with 100% security.</li>
        </ul>
        <h3>How to Use the YAML to JSON Converter</h3>
        <ol>
        <li><strong>Upload Your YAML File</strong>: Click on the upload area and upload the YAML file.</li>
        <li><strong>Convert with One Click</strong>: Hit the "Convert" button and watch as your file is instantly translated to JSON format.</li>
        <li><strong>Download Your JSON File</strong>: Once the conversion is complete, you can copy the new JSON into the clipboard just hitting the "copy" button.</li>
        </ol>
        <h3>Real-World Applications</h3>
        <p>The versatility of our converter makes it suitable for a wide range of applications, from simplifying development workflows to aiding in educational environments where students learn about data formats. It's an invaluable tool for:</p>
        <ul>
        <li><strong>Software Development</strong>: Easily convert configuration files to the required format for various development environments.</li>
        <li><strong>API Integration</strong>: Translate YAML responses or requests to JSON for APIs that only accept JSON format.</li>
        <li><strong>Educational Purposes</strong>: A practical tool for teaching and understanding the differences and applications of YAML and JSON formats.</li>
        </ul>
        <h3>FAQs</h3>
        <p><strong>Q: Is there a limit to the file size I can convert?</strong><br />A: While there's no set limit, extremely large files may take longer to convert, it depends uniquely on the RAM your device can hanlde since (as mentioned before), we don't do any computations in our servers to guarantee 100% privacy</p>
        <p><strong>Q: Can I convert multiple files at once?</strong><br />A: Currently, our tool supports single file conversion to ensure each file receives the attention it deserves for an accurate translation.</p>
        <p><strong>Q: Do I need to install any software?</strong><br />A: No, our YAML to JSON converter is entirely online. There's no need to download or install anything.</p>
        <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;